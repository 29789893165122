import * as React from "react"

import Layout from '../components/Layout'

import { Container, Row, Col } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'

import '../style/main.scss'


// markup
const ProjectsIndex = () => {
  return (
    <>
      <Layout>
        
      </Layout>
    </>
  )
}

export default ProjectsIndex